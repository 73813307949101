<template>
  <div class="container mx-auto">
    <div class="flex justify-between items-center py-4">
      <div
        @click="toggleStartButton()"
        class="button w-40 h-16 bg-blue-500 cursor-pointer select-none active:translate-y-2 active:[box-shadow:0_0px_0_0_#1b6ff8,0_0px_0_0_#1b70f841] active:border-b-[0px] transition-all duration-150 [box-shadow:0_10px_0_0_#1b6ff8,0_15px_0_0_#1b70f841] rounded-full border-[1px] border-blue-400"
      >
        <span
          class="flex flex-col justify-center items-center h-full text-white font-bold text-lg"
          >{{ buttonState }}</span
        >
      </div>
      <div class="flex justify-between">
        <add-client-modal></add-client-modal>
      </div>
      <theme-toggle-button></theme-toggle-button>
    </div>
  </div>

  <clients-list></clients-list>
</template>

<script setup>
import { ref, inject } from "vue";
import ThemeToggleButton from "@/components/shared/ThemeToggleButton.vue";
import AddClientModal from "@/components/home/AddClientModal.vue";
import ClientsList from "@/components/home/ClientsList.vue";
import { useClientsStore } from "@/stores/clientStore";
import { useToast } from "vue-toastification";
const toast = useToast();
/* eslint-disable no-unused-vars */
const axios = inject("axios");
const buttonState = ref("Start");
const orders = ref([]);
const clientStore = useClientsStore();
const masterClient = ref([]);
const followClients = ref([]);
let isPlacingOrder = false;

const changeButtonState = (state) => {
  buttonState.value = state;
};
const toggleStartButton = () => {
  let intervalId;
  if (buttonState.value == "Running") {
    stop();
  } else {
    start();
    intervalId = setInterval(() => {
      getOrders();
      checkIfCanBet();
    }, 2000);
  }
  if (buttonState.value == "Stopped") {
    clearInterval(intervalId);
  }
};

const start = () => {
  localStorage.removeItem("orders");
  masterClient.value = clientStore.getMasterClient;
  followClients.value = clientStore.getFollowerClients;
  getOrders();
  changeButtonState("Running");
};

const stop = () => {
  localStorage.removeItem("orders");
  changeButtonState("Stopped");
  window.location.reload();
};
const getOrders = () => {
  axios
    .get("orders", {
      headers: { "access-token": masterClient.value.accessToken },
    })
    .then((res) => {
      orders.value = res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

const checkIfCanBet = () => {
  if (isPlacingOrder) {
    return;
  }
  let oldOrders = JSON.parse(localStorage.getItem("orders"));
  const isEqual =
    JSON.stringify(oldOrders) === JSON.stringify(Array.from(orders.value));
  if (oldOrders == null || isEqual) {
    if (oldOrders == null) {
      localStorage.setItem("orders", JSON.stringify(orders.value));
    }
  } else {
    console.log(oldOrders);
    console.log("place Order");
    toast.success("Order Placed");
    localStorage.setItem("orders", JSON.stringify(orders.value));
    isPlacingOrder = true;
    const latestOrder = orders.value[0];
    if (latestOrder != null) {
      followClients.value.forEach((client) => {
        console.log("placing an Order");
        placeOrder(
          client.accessToken,
          latestOrder.tradingSymbol,
          latestOrder.transactionType,
          latestOrder.quantity,
          latestOrder.price,
          latestOrder.productType,
          latestOrder.orderType
        );
      });
    } else {
      console.log("No latest Order found");
    }

    isPlacingOrder = false;
  }
};

const placeOrder = async (
  accessToken,
  symbol,
  transactionType, // 'BUY' or 'SELL'
  quantity,
  price, // Optional for MARKET orders
  productType, // 'INTRADAY' or 'DELIVERY'
  orderType // 'LIMIT', 'MARKET', 'SL', 'OCO', etc.
) => {
  try {
    const data = {
      tradingSymbol: symbol,
      transactionType: transactionType,
      quantity: quantity,
      productType: productType,
      orderType: orderType,
    };

    if (orderType === 'LIMIT') {
      if (!price) {
        throw new Error('Price is required for LIMIT orders.');
      }
      data.price = price;
    }

    const response = await axios.post('orders', data, {
      headers: {
        'access-token': accessToken,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 200) {
      console.log('Order placed successfully:', response.data);
      return response.data; // Return order details for further processing (optional)
    } else {
      throw new Error(
        `Order placement failed: ${response.status} - ${response.data.message}`
      );
    }
  } catch (error) {
    console.error('Error placing order:', error.message);
    // Handle errors gracefully (e.g., display error message to user, retry with backoff)
  }
};
</script>
