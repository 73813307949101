import axios from "axios";
let headers;
if (localStorage.token) {
    headers = {
        Accept: "application/json",
        Authorization: `Bearer ${localStorage.token}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    };
} else {
    headers = {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
    };
}
const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: headers,
});

export default instance;