<template>


  <div
    v-if="isModalVisible"
    class="fixed inset-0 flex items-center justify-center overflow-y-auto overflow-x-hidden z-50"
  >
    <div class="absolute inset-0 bg-black opacity-50"></div>
    <div
      class="relative p-4 w-full max-w-md bg-white rounded-lg shadow dark:bg-gray-700"
    >
      <!-- Modal content -->
      <div
        class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600"
      >
        <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
         Edit Client
        </h3>
        <button
          @click="toggleModal"
          type="button"
          class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
          aria-label="Close modal"
        >
          <svg
            class="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
      <!-- Modal body -->
      <div class="p-4 md:p-5">
        <div class="mb-6">
          <label
            for="name"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Name</label
          >
          <input
            type="text"
            id="name"
            v-model="clientRef.name"
            placeholder="Name"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div class="mb-6">
          <label
            for="access_token"
            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >Access Token</label
          >
          <input
            type="text"
            id="access_token"
            v-model="clientRef.accessToken"
            placeholder="Access Token"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div class="flex items-center mb-6">
          <input
            id="link-checkbox"
            type="checkbox"
            value=""
            v-model="clientRef.isMaster"
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          />
          <label
            for="link-checkbox"
            class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >Master Account.</label
          >
        </div>
        <button
          type="button"
          @click="updateClient()"
          class="focus:outline-none text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-900"
        >
          Update
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref,defineExpose,defineProps, onMounted } from "vue";

import { useClientsStore } from '@/stores/clientStore'; // Adjust the path accordingly

onMounted(()=>{
    clientRef.value = props.client
})

const clientsStore = useClientsStore();

const isModalVisible = ref(false);
const clientRef = ref(
  Object()
);
const toggleModal = () => {
  isModalVisible.value = !isModalVisible.value;
};

const updateClient = () => {
console.log(clientRef.value);
clientsStore.update(props.itemIndex,clientRef.value)
clientsStore.getClients
toggleModal()
};

defineExpose({
    toggleModal
})

const props = defineProps({
  itemIndex: Number,
  client:Object
})
</script>

<style>
/* Your styles here */
</style>
