<template>
  <div class="w-screen mt-5">
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th scope="col" class="px-6 py-3">Name</th>

            <th scope="col" class="px-6 py-3">Access Token</th>
            <th scope="col" class="px-6 py-3">Token Status</th>
            <th scope="col" class="px-6 py-3">Status</th>
            <th scope="col" class="px-6 py-3">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(client,index) in allClients"
            :key="client"
            class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
          >
            <th
              scope="row"
              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <div class="flex">
                {{ client.name }}
                <img
                  v-if="client.isMaster"
                  height="10px"
                  width="20px"
                  src="@/assets/crown.webp"
                  alt=""
                  srcset=""
                />
              </div>
            </th>
            <td
              class="px-6 py-4 max-w-xs overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {{ client.accessToken }}
            </td>
            <td class="px-6 py-4">
              {{ client.tokenStatus }}
            </td>
            <td class="px-6 py-4">
              <button @click="clientsStore.toggleStatus(index)" v-if="client.status" class="rounded bg-green-500 text-white p-1 text-sm">Active</button>
              <button @click="clientsStore.toggleStatus(index)" v-else  class="rounded bg-red-500 text-white p-1 text-sm">DeActive</button>
            </td>
            <td class="px-6 py-4">
             <edit-client-modal :item-index="index" :client="client" ref="editClientModals"></edit-client-modal>
             <delete-client-modal :item-index="index" ref="deleteClientModals"></delete-client-modal>
              <div class="flex">
                <button 
                  class="pr-4" @click="toggleEditModalByIndex(index)"
                  > <img
                    height="10px"
                    width="20px"
                    src="@/assets/edit.png"
                    alt=""
                    srcset=""
                  /></button
                >
                <button @click="toggleDeleteModalByIndex(index)"
                >
                  <img
                    height="10px"
                    width="20px"
                    src="@/assets/delete.png"
                    alt=""
                    srcset=""
                  />
              </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";

import { useClientsStore } from '@/stores/clientStore';
import EditClientModal from "./EditClientModal.vue";
import DeleteClientModal from "./DeleteClientModal.vue";
onMounted(() => {
  getClients();
});
const clientsStore = useClientsStore();
const allClients = ref(Object);
const editClientModals = ref([]);
const deleteClientModals = ref([]);
const getClients = () => {
  allClients.value = clientsStore.getClients
};
const toggleEditModalByIndex = (index) => {
  const card =editClientModals.value[index];
  if (card) {
    card.toggleModal();
  } else {
    console.error(`Card at index ${index} not found.`);
  }
};

const toggleDeleteModalByIndex = (index) => {
  const card = deleteClientModals.value[index];
  if (card) {
    card.toggleModal();
  } else {
    console.error(`Card at index ${index} not found.`);
  }
};




</script>

<style>
</style>