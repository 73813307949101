import { createApp } from 'vue'
import { createPinia } from "pinia";
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import "./css/app.css";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axiosInstance from "./axiosInstance";
const pinia = createPinia();

const app = createApp(App)
app.provide("axios", axiosInstance);
app.use(router)
app.use(Toast)
app.use(pinia);
app.mount('#app')