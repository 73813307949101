import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
const toast = useToast();
export const useClientsStore = defineStore("clients", {
    state: () => ({ clients: JSON.parse(localStorage.getItem("clients")) || [] }),
    getters: {
        getClients: (state) => state.clients,
        getMasterClient: (state) => {
            for (const client of state.clients) {
                if (client.isMaster) {
                    return client;
                }
            }
            return null;
        },
        getFollowerClients: (state) =>
            state.clients.filter((client) => !client.isMaster),
    },
    actions: {
        add(newClient) {
            if (!newClient.name || !newClient.accessToken) {
                toast.error("Name and Access Token fields are required.");
                return;
            }

            const existingClients = this.clients; // Corrected: use state.clients

            // If the new client is a master, unset isMaster for all other clients
            if (newClient.isMaster) {
                existingClients.forEach((client) => {
                    client.isMaster = false;
                });
            }

            existingClients.push(newClient); // Push the new client into the array

            // Update the store state
            this.clients = existingClients;

            // Update localStorage
            localStorage.setItem("clients", JSON.stringify(existingClients));

            toast.success("Client added successfully!");
        },

        update(index, updatedClient) {
            if (index >= 0 && index < this.clients.length) {
                if (updatedClient.isMaster) {
                    this.clients.forEach((client) => {
                        if (client != updatedClient) {
                            client.isMaster = false;
                        }
                    });
                }
                this.clients[index] = updatedClient;

                localStorage.setItem("clients", JSON.stringify(this.clients));
            } else {
                console.error(`Invalid index ${index}.`);
            }
        },
        delete(index) {
            if (index >= 0 && index < this.clients.length) {
                this.clients.splice(index, 1);
                localStorage.setItem("clients", JSON.stringify(this.clients));
            } else {
                console.error(`Invalid index ${index}.`);
            }
        },
        toggleStatus(index) {
            if (index >= 0 && index < this.clients.length) {
                const client = this.clients[index];
                client.status = !client.status; // Toggle the status of the client
                localStorage.setItem("clients", JSON.stringify(this.clients)); // Update localStorage
            } else {
                console.error(`Invalid index ${index}.`);
            }
        },
    },
});