<template>
  <div class="bg-gray-100 h-screen text-gray-700 dark:bg-slate-900 dark:text-white">
    <router-view/>
  </div>
</template>

<style lang="scss">


</style>
<script setup>
import {onBeforeMount} from "vue"
onBeforeMount(()=>{
  
   checkDarkMode()
})
 const checkDarkMode=  ()=>{
   if (localStorage.getItem('color-theme') === 'dark' || (!('color-theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        document.documentElement.classList.add('dark');
    } else {
        document.documentElement.classList.remove('dark')
    }
  }
</script>